const en = {
  //language
  langOption: {
    lang_title: "Language",
    lang_tw: "Traditional Chinese",
    lang_en: "English",
  },
  //language

  //navbar
  navBasic: {
    title: "Basic",
    stores: "Stores",
    terminals: "Terminals",
    departments: "Departments",
    employees: "Employees",
    foodmajorkinds: "Food Major Kinds",
    foodkinds: "Food Kinds",
    foods: "Foods",
    tastekinds: "Taste Kinds",
    combokinds: "Combo Kinds",
    functions: "Functions",
    pays: "Pays",
    directorykinds: "Directory Kinds",
    accounts: "Accounts",
    pricelabels: "Pricelabels",
    brands: "Brands",
    warehouses: "Warehouses",
    suppliers: "Suppliers",
  },
  navModules: {
    title: "Modules",
    authority: "Authority",
    function: "Function",
    invisible: "Invisible",
    sequence: "Sequence",
    promotion: "Promotion",
    day: "Day",
    stores: "Stores",
  },
  navReport: {
    title: "Report",
    saletitle: "Sales",
    sale: "Sales",
    classsale: "Class Sale",
    invoice: "Invoice",
    invoiceprize: "Invoice Prize",
    employeeanalysis: "Employee Analysis",
    workrecord: "Work Record",
    saler: "Salesperson",
    people: "People",
    coupon: "Coupon",
    saleorder: "Sale Order",
    collectionpayment: "Collection Payment",
    orderfoodstatistic: "Order Food Statistic",
    foodmajorkindstatistic: "Food Major Kind Statistic",
    foodkindstatistic: "Food Kind Statistic",
    foodsalestatistic: "Food Sale Statistic",
    fooddaystatistic: "Food Day Statistic",
    foodsale: "Food Sale",
    free: "Free",
    customerfree: "Customer Free",
    timestatistic: "Time Statistic",
    tastetatistic: "Taste Statistic",
    pay: "Pay",
    salepay: "Sale Pay",
    erptitle: "ERP",
    supplier: "Supplier",
    supplierfoodsale: "Supplier Food Sale",
    supplierfoodsaleoriginal: "Supplier Food Sale Original",
    supplierpurchaseorder: "Supplier Purchase Order",
    foodpurchaseorder: "Food Purchase Order",
    foodpurchase: "Food Purchase",
    foodpurchasedetail: "Food Purchase Detail",
    purchaseorder: "Purchase Order",
    purchase: "Purchase",
    purchasereturn: "Purchase Return",
    stockadjust: "Stock Adjust",
    stockadjustdirectory: "Stock Adjust Directory",
    stocktransfers: "Stock Transfers",
    inventory: "Inventory",
    stock: "Stock",
    safeqty: "Safe Qty",
    inventorypandl: "Inventory P and L",
    foodmovement: "Food Movement",
    combinationtrade: "Combination Trade",
    seperationtrade: "Seperation Trade",
    customertitle: "Customer",
    customer: "Customer",
    customerfoodsale: "Customer Food Sale",
    customersalestatistic: "Customer Sale Statistic",
    deposit: "Deposit",
    integral: "Integral",
    customerstock: "Customer Stock",
    customerstockmovement: "Customer Stock Movement",
    pgtalkdeposit: "PGTalk Deposit",
    saleprofit: "Sale Profit",
    creditcard: "Credit Card",
    stockbatch: "Stock Batch",
    stockbatchmovement: "Stock Batch Movement",
    erpsummary: "ERP Summary",
  },
  navCustomer: {
    title: "Customer",
    customers: "Customers",
    membersetting: "Member Setting",
    memberupgraderules: "Member Upgrade Rules",
    integralrules: "Integral Rules",
    ocardrules: "Ocard Rules",
    memberweb: "Member Web",
  },
  navERP: {
    titleLYERP: "LY ERP",
    titleDWERP: "DW ERP",
    titleERP: "ERP",
    titleInvoice: "Invoice",
    sals: "Sales",
    salreturns: "Sale returns",
    purchaseorders: "Purchase Orders",
    purchases: "Purchases",
    purchasereturns: "Purchase Returns",
    stockadjusts: "Stock Adjusts",
    stocktransfers: "Stock Transfers",
    inventorys: "Inventorys",
    inventorymachine: "Inventory Machine",
    safeqty: "Safe Qty",
    lystock: "LY Stock",
    dwstock: "DW Stock",
  },
  navCombination: {
    title: "Combination",
    combinations: "Combinations",
    combinationtrades: "Combination Trades",
    seperationtrades: "Seperation Trades",
  },
  navAccount: {
    title: "Account",
    receivables: "Receivables",
    receipts: "Receipts",
    payables: "Payables",
    payments: "Payments",
  },
  navOnlineOrder: {
    title: "Online Order",
    onlineorderstore: "Online Order Store",
    onlineorderwork: "Online Order Work",
    onlineorderfood: "Online Order Food",
    ubereats: "UberEats",
  },
  navDownload: {
    title: "Download",
    downloads: "Downloads",
  },
  //navbar

  //adminMenu
  adminMenu: {
    company: "Company",
    user: "User",
    password: "Password",
    logout: "Logout",
  },
  //adminMenu

  //viewName
  viewName: {
    home: "Home",
    stores: "Stores",
    businesses: "Businesses",
    utaiforms: "Utai Forms",
    ushowforms: "UShow Forms",
    invoiceprizes: "Invoice Prizes",
    devices: "Devices",
    users: "Users",
    terminals: "Terminals",
    employees: "Employees",
    customers: "Customers",
    membersetting: "Member Setting",
    memberupgraderules: "Member Upgrade Rules",
    integralrules: "Integral Rules",
    ocardrules: "Ocard Rules",
    foodmajorkinds: "Food Major Kinds",
    foodkinds: "Food Kinds",
    foods: "Foods",
    departments: "Departments",
    tastekinds: "Taste Kinds",
    foodkindtastes: "Food Kind Tastes",
    foodtastes: "Food Tastes",
    combokinds: "Combo Kinds",
    foodcombos: "Food Combos",
    functions: "Functions",
    comboadd: "Combo Add",
    downloads: "Downloads",
    downloadadd: "Download Add",
    moduleauthority: "Module Authority",
    modulefoodkind: "Module Food Kind",
    modulefood: "Module Food",
    modulestore: "Module Store",
    modulestoreselect: "Module Store Select",
    dirmoduledevice: "Module Device",
    moduleterminal: "Module Terminal",
    moduleemployee: "Module Employee",
    moduleinvisible: "Module Invisible",
    modulesequence: "Module Sequence",
    modulefunction: "Module Function",
    authority: "Authority",
    foodkindsequence: "Food Kind Sequence",
    foodsequence: "Food Sequence",
    functionbutton: "Function Button",
    functionbuttonadd: "Function Button Add",
    reportsale: "Sale",
    reportsaldata: "Sale Data",
    reportinvoice: "Invoice",
    reportinvoiceprize: "Invoice Prize",
    reportcustomer: "Customer",
    customersimple: "Customer Simple",
    customerdetail: "Customer Detail",
    reportsupplier: "Supplier",
    suppliersimple: "Supplier Simple",
    supplierdetail: "Supplier Detail",
    reportsupplierfoodsale: "Supplier Food Sale",
    reportsupplierfoodsaleoriginal: "Supplier Food Sale (original)",
    supplierfoodsale: "Supplier Food Sale Statistic",
    invoicedetail: "Invoice Detail",
    invoicesimple: "Invoice Simple",
    reportworkrecord: "Work Record",
    workrecorddetail: "Work Record Detail",
    workrecordsimple: "Work Record Simple",
    saledetail: "Sale Detail",
    salesimple: "Sale Simple",
    saleorderdetail: "Sale Order Detail",
    saleordersimple: "Sale Order Simple",
    salesummary: "Sale Summary",
    foodsalestatistic: "Food Sale Statistic",
    fooddaystatistic: "Food Day Statistic",
    foodsaledetail: "Food Sale Detail",
    reportfoodsale: "Food Sale",
    reportcollectionpayment: "Collection Payment",
    collectionpaymentdetail: "Collection Payment Detail",
    foodkindstatistic: "Food Kind Statistic",
    foodmajorkindstatistic: "Food Major Kind Statistic",
    reportfree: "Free",
    freestatistic: "Free Statistic",
    reportcustomerfree: "Customer Free",
    customerfreestatistic: "Customer Free Statistic",
    freedetail: "Free Detail",
    timestatistic: "Time Statistic",
    employeeanalysis: "Employee Analysis",
    modulepromotion: "Module Promotion",
    directorykinds: "Directory Kinds",
    pays: "Pays",
    promotion: "Promotion",
    sales: "Sales",
    salereturns: "Sale Returns",
    inventorys: "Inventorys",
    inventoryadd: "Inventory Add",
    inventoryedit: "Inventory Edit",
    stockadjusts: "Stock Adjusts",
    stockadjustadd: "Stock Adjust Add",
    stockadjustedit: "Stock Adjust Edit",
    stocktransfers: "Stock Transfers",
    stocktransferadd: "Stock Transfer Add",
    stocktransferedit: "Stock Transfer Edit",
    stocktransfercheck: "Stock Transfer Check",
    sals: "Sales",
    saladd: "Sale Add",
    saledit: "Sale Edit",
    salreturns: "Sale Returns",
    salreturnadd: "Sale Returns Add",
    salreturnedit: "Sale Returns Edit",
    purchases: "Purchases",
    purchaseorderturn: "Purchase Order Turn",
    purchaseadd: "Purchase Add",
    purchaseedit: "Purchase Edit",
    purchasereturns: "Purchase Returns",
    purchasereturnadd: "Purchase Return Add",
    purchasereturnedit: "Purchase Return Edit",
    purchaseorders: "Purchase Orders",
    purchaseorderadd: "Purchase Order Add",
    purchaseorderedit: "Purchase Order Edit",
    payments: "Payments",
    paymentadd: "Payment Add",
    paymentedit: "Payment Edit",
    receivables: "Receivables",
    receipts: "Receipts",
    receiptadd: "Receipt Add",
    receiptedit: "Receipt Edit",
    stock: "Stock",
    payables: "Payables",
    reportfoodmovement: "Food Movement",
    foodmovementsimple: "Food Movement Simple",
    foodmovementdetail: "Food Movement Detail",
    paymentsimple: "Payment Simple",
    paymentdetail: "Payment Detail",
    payablesimple: "Payable Simple",
    payabledetail: "Payable Detail",
    receiptsimple: "Receipt Simple",
    receiptdetail: "Receipt Detail",
    receivablestatistic: "Receivable Statistic",
    receivablesimple: "Receivable Simple",
    receivabledetail: "Receivable Detail",
    paydetail: "Pay Detail",
    paysimple: "Pay Simple",
    reportpay: "Pay",
    salepaydetail: "Sale Pay Detail",
    salepaysimple: "Sale Pay Simple",
    reportsalepay: "Sale Pay",
    dirday: "Dir Day",
    daymodule: "Day Module",
    day: "Day",
    warehouses: "Warehouses",
    suppliers: "Suppliers",
    tastestatistic: "Taste Statistic",
    pricelabels: "Price Labels",
    brands: "Brands",
    safeqty: "Safe Qty",
    reportdeposit: "Deposit",
    depositsaledetail: "Deposit Sale Detail",
    depositstoredetail: "deposit Store Detail",
    reportsafeqty: "Safe Qty",
    supplierfoods: "Supplier Foods",
    pyreceivables: "PY Receivables",
    reportinventorypandl: "Inventory P and L",
    inventorypandldetail: "Inventory P and L Detail",
    reportclasssale: "Class Sale",
    classsalesimple: "Class Sale Simple",
    classsalestatistic: "Class Sale Statistic",
    salesimplesaler: "Sale Simple Salesperson",
    saledetailsaler: "Sale Detail Salesperson",
    daystatisticsaler: "Day Statistic Salesperson",
    reportsaler: "Salesperson",
    reportpurchase: "Purchase",
    reportpurchasereturn: "Purchase Return",
    reportpurchaseorder: "Purchase Order",
    purchasesimple: "Purchase Simple",
    purchasedetail: "Purchase Detail",
    purchasereturnsimple: "Purchase Return Simple",
    purchasereturndetail: "Purchase Return Detail",
    purchaseordersimple: "Purchase Order Simple",
    purchaseorderdetail: "Purchase Order Detail",
    reportsupplierpurchaseorder: "Supplier Purchase Order",
    supplierpurchaseorderdetail: "Supplier Purchase Order Detail",
    reportfoodpurchaseorder: "Food Purchase Order",
    foodpurchaseorder: "Food Purchase Order",
    foodpurchaseorderdetail: "Food Purchase Order Detail",
    reportfoodpurchase: "Food Purchase Order Detail",
    foodpurchaseorder: "Food Purchase Order",
    foodpurchase: "Food Purchase",
    foodpurchasedetail: "Food Purchase Detail",
    reportstockadjust: "Stock Adjust",
    stockadjustsimple: "Stock Adjust Simple",
    stockadjustdetail: "stock Adjust Detail",
    reportstockadjustdirectory: "Stock Adjust Directory",
    reportstocktransfers: "Stock Transfers",
    stocktransfersimple: "Stock Transfer Simple",
    stocktransferdetail: "Stock Transfer Detail",
    peopledetail: "People Detail",
    peoplesimple: "People Simple",
    peoplestatistic: "People Statistic",
    reportpeople: "People",
    reportintegral: "Integral",
    integraltradedetail: "Integral Trade Detail",
    integraltradestatistic: "Integral Trade Statistic",
    lystock: "LY Stock",
    dwstock: "DW Stock",
    reportsaleorder: "Sale Order",
    reportorderfoodstatistic: "Order Food Statistic",
    inventorymachine: "Inventory Machine",
    reportinventory: "Inventory",
    inventorysimple: "Inventory Simple",
    inventorydetail: "Inventory Detail",
    accounts: "Accounts",
    coupondetail: "Coupon Detail",
    reportcoupon: "Coupon",
    onlineorderstore: "Online Order Store",
    onlineorderwork: "Online Order Work",
    moduleonlineorderfood: "Module Online Order Food",
    ubereats: "UberEats",
    lineordersetting: "LINE Order Setting",
    combinations: "Combinations",
    combinationedit: "Combination Edit",
    combinationtrades: "Combination Trades",
    combinationtradeadd: "Combination Trade Add",
    combinationtradeedit: "Combination Trade Edit",
    seperationtrades: "Seperation Trades",
    seperationtradeadd: "Seperation Trade Add",
    combinationtradereturn: "Combination Trade Return",
    seperationtradeedit: "Seperation Trade Edit",
    reportcombinationtrade: "Combination Trade",
    reportseperationtrade: "Seperation Trade",
    reportcustomerstock: "Customer Stock",
    reportcustomerstockmovement: "Customer Stock Movement",
    reportcustomerfoodsale: "Customer Food Sale",
    reportcustomersalestatistic: "Customer Sale Statistic",
    reportpgtalkdeposit: "PGTalk Deposit",
    pgtalkdepositdetail: "PGTalk Deposit Detail",
    customerstock: "Customer Stock",
    customerstockmovementdetail: "Customer Stock Movement Detail",
    reportsaleprofit: "Sale Profit",
    reportcreditcard: "Credit Card",
    reportstockbatch: "Stock Batch",
    reportstockbatchmovement: "Stock Batch Movement",
    reporterpsummary: "ERP Summary",
    changeprice: "Change Price",
    memberweb: "Member Web,",
    specifications: "Specification",
    errormessage: "Error Message",
    thirdparty: "Third Party",
    system: "System",
    notification: "Notification",
    webhook: "Webhook",
    tablemaps: "Tables",
    foodbrands: "Food Brands",
    integralfoodkind: "Food Kind",
    integralfood: "Food",
  },
  //viewName

  //columnName
  columnName: {
    name: "Name",
    storenumber: "Store Number",
    storename: "Store Name",
    terminalnumber: "Terminal Number",
    terminalname: "Terminal Name",
    foodkindnumber: "Food Kind Number",
    foodkindname: "Food Kind Name",
    onlineordercategoryname: "Online Order Category Name",
    foodmajorkindnumber: "Food Major Kind Number",
    foodmajorkindname: "Food Major Kind Name",
    barcode: "Barcode",
    barcode2: "Barcode 2",
    barcode3: "Barcode 3",
    departmentnumber: "Department Number",
    departmentname: "Department Name",
    foodnumber: "Food Number",
    onlineorderitemname: "Online Order Item Name",
    foodname: "Food Name",
    foodmemo: "Memo",
    qty: "Qty",
    safeqty: "Safe Qty",
    lackqty: "Lack Qty",
    pickedqty: "Picked Qty",
    notpickedqty: "Not Picked Qty",
    salprice: "Sale Price",
    salprice1: "Sale Price 1",
    salprice2: "Sale Price 2",
    salprice3: "Sale Price 3",
    salprice4: "Sale Price 4",
    salprice5: "Sale Price 5",
    salprice6: "Sale Price 6",
    cost: "Cost",
    totalcost: "Total Cost",
    avgcost: "Avg Cost",
    totalavgcost: "Total Avg Cost",
    differencetotalcost: "Difference Total Cost",
    differencetotal: "Difference Total",
    grossprofit: "Gross Profit",
    profit: "Profit",
    grossmargin: "Gross Margin",
    foodsaleproportion: "Food Sale Proportion",
    saleproportion: "Sale Proportion",
    total: "Total",
    saltotal: "All Total",
    alltotal: "All Total",
    saletotal: "Sale Total",
    salereturntotal: "Sale Return Total",
    totaldiscountprice: "Total Discount Price",
    discountprice: "Discount Price",
    totalfreeprice: "Total Free Price",
    freeprice: "Free Price",
    unit: "Unit",
    employeenumber: "Employee Number",
    employeename: "Employee Name",
    salernumber: "Salesperson Number",
    salername: "Salesperson Name",
    warehousenumber: "Warehouse Number",
    warehousename: "Warehouse Name",
    fromwarehousenumber: "From Warehouse Number",
    fromwarehousename: "From Warehouse Name",
    towarehousenumber: "To Warehouse Number",
    towarehousename: "To Warehouse Name",
    suppliernumber: "Supplier Number",
    suppliername: "Supplier Name",
    purchasetotal: "Purchase Total",
    purchasereturntotal: "Purchase Return Total",
    salefoodname: "Sale Food Name",
    customernumber: "Customer Number",
    customername: "Customer Name",
    email: "Email",
    telephone: "Telephone",
    cellphone: "Cellphone",
    memo: "Memo",
    tradememo: "Trade Memo",
    salmemo: "Sale Memo",
    foodmemo: "Food Memo",
    gender: "Gender",
    username: "User Name",
    roletype: "Role Type",
    businessid: "Business ID",
    businessname: "Business Name",
    combokindname: "Combo Kind Name",
    comboname: "Combo Name",
    tastekindname: "Taste Kind Name",
    onlineordermodifiergroupname: "Online Order Modifier Group Name",
    tastename: "Taste Name",
    onlineordermodifiername: "Online Order Modifier Name",
    price: "Price",
    invoicedate: "Invoice Date",
    invoicenumber: "Invoice Number",
    period: "Period",
    postalcode: "Postal Code",
    address: "Address",
    phone: "Phone",
    invoiceemail: "Invoice Email",
    invoiceprizetype: "Invoice Prize Type",
    invoiceprizeamount: "Invoice Prize Amount",
    invoiceprizenumber: "Invoice Prize Number",
    businessnumber: "Business Number",
    purchasenumber: "Purchase Number",
    salnumber: "Sale Number",
    tradenumber: "Trade Number",
    depositrecordnumber: "Deposit Record Number",
    purchasedate: "Purchase Date",
    businessday: "Business Day",
    businessclass: "Business Class",
    stockadjustnumber: "Stock Adjust Number",
    stockadjustdate: "Stock Adjust Date",
    fromstockadjustnumber: "From Stock Adjust Number",
    tostockadjustnumber: "To Stock Adjust Number",
    stocktransfernumber: "Stock Transfer Number",
    stocktransferdate: "Stock Transfer Date",
    checkqty: "Check Qty",
    checkername: "Checker Name",
    salername: "Salesperson Name",
    buildername: "Builder Name",
    paytype: "Pay Type",
    payname: "Pay Name",
    functionname: "Function Name",
    value: "Value",
    directorytype: "Directory Type",
    directorytypetext: "Directory Type",
    directorykindname: "Directory Kind Name",
    directoryname: "Directory Name",
    moduletype: "Module Type",
    modulename: "Module Name",
    promotionname: "Promotion Name",
    promotiondetname: "Promotion Det Name",
    buyernumber: "Buyer Number",
    buyername: "Buyer Name",
    receiptnumber: "Receipt Number",
    receiptdate: "Receipt Date",
    paymentnumber: "Payment Number",
    paymentdate: "Payment Date",
    tradetype: "Trade Type",
    tradetypetext: "Trade Type",
    eattype: "Eat Type",
    eattypetext: "Eat Type",
    payable: "Payable",
    payed: "Payed",
    nopay: "No Pay",
    accountdate: "Account Date",
    receivable: "Receivable",
    received: "Received",
    downloadname: "Download Name",
    counts: "Counts",
    salecounts: "Sale Counts",
    voidcounts: "Void Counts",
    foodcounts: "Food Counts",
    foodaveragesalprice: "Food Average Sal Price ",
    totalperreceipt: "Total Per Receipt",
    foodcountsperreceipt: "Food Counts Per Receipt",
    peopleperreceipt: "People Per Receipt",
    totalperperson: "Total Per Person",
    foodcountsperperson: "Food Counts Per Person",
    people: "People",
    time: "Time",
    times: "Times",
    invoicebegin: "Invoice Begin",
    invoiceend: "Invoice End",
    ytaxprice: "Taxable Amount",
    taxprice: "Tax Amount",
    ytaxpriceincludetax: "Price Including Tax",
    ztaxprice: "Zero Tax Amount",
    ntaxprice: "No Tax Amount",
    netprice: "Net Amount",
    difference: "Difference",
    sheets: "Sheets",
    invoicevoid: "Invoice Void",
    voidsheets: "Void Sheets",
    saltime: "Sale Time",
    deliverytime: "Delivery Time",
    tradetime: "Trade Time",
    createtime: "Create Time",
    updatetime: "Update Time",
    cash: "Cash",
    change: "Change",
    uniformnumber: "Uniform Number",
    inventorydate: "Inventory Date",
    inventorynumber: "Inventory Number",
    inventoryqty: "Inventory Qty",
    stockqty: "Stock Qty",
    initialqty: "Initial Qty",
    lastqty: "Last Qty",
    salqty: "Sale Qty",
    voidsalqty: "Void Sale Qty",
    purchaseqty: "Purchase Qty",
    purchaseprice: "Purchase Price",
    purchasereturnqty: "Purchase Return Qty",
    adjustqty: "Adjust Qty",
    combinationinqty: "Combination In Qty",
    combinationoutqty: "Combination Out Qty",
    seperationinqty: "Seperation In Qty",
    seperationoutqty: "Seperation Out Qty",
    transferinqty: "Transfer In Qty",
    transferoutqty: "Transfer Out Qty",
    tradedate: "Trade Date",
    deviceid: "Device ID",
    devicename: "Device Name",
    cpuid: "CUP ID",
    statustype: "Status Type",
    statustypetext: "Status Type",
    principlenumber: "Principle Number",
    principlename: "Principle Name",
    daybegin: "Day Begin",
    dayend: "Day End",
    timebegin: "Time Begin",
    timeend: "Time End",
    sunday: "Sunday",
    monday: "Monday",
    tuesday: "Tuesday",
    wednesday: "Wednesday",
    thursday: "Thursday",
    friday: "Friday",
    saturday: "Saturday",
    daymodulename: "Day Module Name",
    integral: "Integral",
    integraladd: "Integral Add",
    integralminus: "Integral Minus",
    birth: "Birth",
    gender: "Gender",
    degree: "Degree",
    customerleveltypetext: "Customer Level Type",
    days: "Days",
    memberupgraderule: "Member Upgrade Rule",
    memberupgraderuletext: "Degree",
    creditcardprice: "Credit Card Price",
    creditcardnumber: "Credit Card Number",
    paytype1: "Pay Type 1",
    paytype2: "Pay Type 2",
    linepay: "Line Pay",
    scan2pay: "Scan2Pay",
    couponprice: "Coupon Price",
    deposit: "Deposit",
    depositminus: "Deposit Minus",
    lastrechargeamount: "Last Recharge Amount",
    tradenumber: "Trade Number",
    executetime: "Execute Time",
    easycardprice: "Easy Card Price",
    number: "Number",
    overcharge: "Over Charge",
    originalprice: "Original Price",
    originaltotal: "Original Total",
    originalqty: "Original Qty",
    identifier: "Identifier",
    totallength: "Total Length",
    pricelabeltype: "Price Label Type",
    foodposition: "Food Position",
    foodlength: "Food Length",
    priceposition: "Price Position",
    pricelength: "Price Length",
    pricedecimalnumber: "Price Decimal Number",
    qtyposition: "Qty Position",
    qtylength: "Qty Length",
    qtydecimalnumber: "Qty Decimal Number",
    discount: "Discount",
    towork: "To Work",
    offwork: "Off Work",
    worktime: "Work Time",
    worktimehour: "Work Time Hour",
    worktimeminute: "Work Time Minute",
    devicecounts: "Device Counts",
    principle: "Principle",
    contactperson: "Contact Person",
    faxnumber: "Fax Num",
    startdate: "Start Date",
    enddate: "End Date",
    months: "Months",
    monthlyfee: "Monthly Fee",
    freedeposit: "Free Deposit",
    beforedeposit: "Before Deposit",
    afterdeposit: "After Deposit",
    beforestock: "Before Stock",
    peopletype1: "People Type 1",
    peopletype2: "People Type 2",
    peopletype3: "People Type 3",
    peopletype4: "People Type 4",
    totalpeople: "Total People",
    lywarehousenumber: "LY Warehouse Number",
    lyuniformnumber: "LY Uniform Number",
    vehiclenumber: "Vehicle Number",
    originalinvoice: "Original Invoice",
    modulestorename: "Module Store Name",
    isfinalpaymentpayed: "Is Final Payment Payed",
    finalpayment: "Final Payment",
    finalpaymentdate: "Final Payment Date",
    advanceddeposit: "Advanced Deposit",
    producttype: "Product Type",
    producttypetext: "Product Type",
    accountnumber: "Account Number",
    accountname: "Account Name",
    couponname: "Coupon Name",
    expiredate: "Expire Date",
    sourcesalnumber: "Source Sale Number",
    sourcesaltime: "Source Sale Time",
    sourceterminalnumber: "Source Terminal Number",
    sourceterminalname: "Source Terminal Name",
    discountsalnumber: "Discount Sale Number",
    discountsaltime: "Discount Sale Time",
    discountterminalnumber: "Discount Terminal Number",
    discountterminalname: "Discount Terminal Name",
    onlineorderstorename: "Online Order Store Name",
    onlineorderphone: "Online Order Phone",
    onlineorderaddress: "Online Order Address",
    collectionpaymenttype: "Collection Payment Type",
    onlineordermenuname: "Online Order Menu Name",
    minqty: "Min Qty",
    maxqty: "Max Qty",
    taxtype: "Tax Type",
    taxtypetext: "Tax Type",
    starttime: "Start Time",
    endtime: "End Time",
    combinationtradedate: "Combination Trade Date",
    combinationtradenumber: "Combination Trade Number",
    seperationtradedate: "Seperation Trade Date",
    seperationtradenumber: "Seperation Trade Number",
    combinationwarehousenumber: "Combination Warehouse Number",
    combinationwarehousename: "Combination Warehouse Name",
    combinationfoodnumber: "Combination Food Number",
    combinationfoodname: "Combination Food Name",
    combinationqty: "Combination Qty",
    combinationtotal: "Combination Total",
    combinationunit: "Combination Unit",
    stockadjusttype: "Stock Adjust Type",
    stockadjusttypetext: "Stock Adjust Type",
    promotiontype: "Promotion Type",
    promotiontypetext: "Promotion Type",
    balance: "Balance",
    ocardruleid: "Ocard Rule ID",
    ocardrulename: "Ocard Rule Name",
    ocardpromotiontype: "Ocard Promotion Type",
    ocardpromotiontypetext: "Ocard Promotion Type",
    ocardrulefoodnametext: "Ocard Rule Food Name",
    promotionvalue: "Promotion Value",
    salesperson: "Salesperson",
    partner: "Partner",
    utailocationtype: "Utai Location Type",
    utailocationtypetext: "Utai Location Type",
    applytype: "Apply Type",
    applytypetext: "Apply Type",
    termleasetype: "Term Lease Type",
    termleasetypetext: "Term Lease Type",
    companyname: "Company Name",
    companypostalcode: "Company Postal Code",
    companyaddress: "Company Address",
    taxnumber: "Tax Number",
    taxationbureau: "Taxation Bureau",
    storepostalcode: "Store Postal Code",
    storeaddress: "Store Address",
    principalname: "Principal Name",
    principalphone: "Principal Phone",
    principalfax: "Principal Fax",
    principalemail: "Principal Email",
    contactpersonphone: "Contact Person Phone",
    contactpersonfax: "Contact Person Fax",
    contactpersonemail: "Contact Person Email",
    invoicesheets: "Invoice Sheets",
    invoiceincreasesheets: "Invoice Increase Sheets",
    maingoods: "Main Goods",
    receiver: "Receiver",
    receiveraddress: "Receiver Address",
    receiverphone: "Receiver Phone",
    applicationid: "Application ID",
    ishead: "Is Head",
    isheadtext: "Is Head",
    extraprice: "Extra Price",
    isselect: "Is Select",
    add: "Add",
    remove: "Remove",
    pricemarkup: "Price Markup",
    alladd: "All Add",
    min: "Min",
    max: "Max",
    name: "Name",
    use: "Use",
    delete: "Delete",
    fullnumber: "Full",
    isrepeat: "Is Repeat",
    modify: "Modify",
    isvoid: "Is Void",
    changestatus: "Change Status",
    status: "Status",
    select: "Select",
    selectall: "Select All",
    item: "Item",
    isinvoicehassend: "Is Invoice Has Send",
    isinvoicehasprint: "Is Invoice Has Print",
    pgtalk: "PGTalk",
    pgtalkpt: "PGTalk PT",
    validmonths: "Valid Months",
    keeptotal: "Keep Total",
    keepmonths: "Keep Months",
    visiontype: "Vision Type",
    visiontypetext: "Vision Type",
    builddate: "Build Date",
    batchnumber: "Batch Number",
    phone1: "Phone 1",
    phone2: "Phone 2",
    changepricetype: "Change Price",
    changepricetypetext: "Change Price",
    salpricetype: "Sale Price",
    salpricetypetext: "Sale Price",
    hasbatchnumber: "Has Batch Number",
    purchaseorderqty: "Purchase Order Qty",
    saleorderqty: "Sale Order Qty",
    sequence: "Sequence",
    agencystartdate: "Agency Start Date",
    availablyqty: "Availably Qty",
    downloaditemname: "Download Item Name",
    issuccess: "Is Success",
    issuccesstext: "Is Success",
    onlineordermenu: "Menu",
    onlineordermenutext: "Menu",
    degreestartdate: "Degree Start Date",
    degreeenddate: "Degree End Date",
    specificationtypename: "Specification Type Name",
    specificationname: "Specification Name",
    terminalid: "Terminal ID",
    apitype: "API Type",
    apitypetext: "API Type",
    requesturl: "Request URL",
    requestmethod: "Request Method",
    responsecode: "Response Code",
    issucccess: "Is Success",
    durationtime: "Duration Time",
    requesttime: "Request Time",
    thirdpartytype: "Third Party Type",
    thirdpartytypetext: "Third Party Type",
    httpmethod: "HTTP Method",
    durationseconds: "Duration Seconds",
    userid: "User ID",
    systemlogtype: "System Log Type",
    systemlogtypetext: "System Log Type",
    description: "Description",
    requestcontent: "Request Content",
    responsecontent: "Response Content",
    requestbody: "Request Body",
    notificationtype: "Notification Type",
    notificationtypetext: "Notification Type",
    orderid: "Order ID",
    lastnotificationtime: "Last Notification Time",
    resendtimes: "Resend Times",
    webhooklogid: "Webhook Log ID",
    webhooktype: "Webhook Type",
    webhooktypetext: "Webhook Type",
    clientip: "Client IP",
    transactionid: "Transaction ID",
    errormsg: "Error MSG",
    signature: "Signature",
    id: "ID",
    apptype: "App Type",
    invoicetime: "Invoice Time",
    floormapid: "Floor Map ID",
    tablemapid: "Table Map ID",
    tablemapname: "Table Map Name",
    partid: "Part ID",
    index: "Index",
    body: "Body",
    thirdpartybody: "Third Party Body",
    authcode: "Auth Code",
    cardnumber: "Card Number",
    creditcardtradenumber: "Credit Card Trade Number",
    brandname: "Brand Name",
    brandnumber: "Brand Number",
    broadcastid: "Broadcast Id",
    version: "Version",
  },
  //columnName

  //dataBodyPart
  dataBodyPart: {
    search: "Search",
    directorykind: "Directory Kind",
    qty: "Qty",
  },
  //dataBodyPart

  //dataModalPart
  dataModalPart: {
    none: "None",
    number: "Number",
    name: "Name",
    food: "Food",
    foodkindid: "Food Kind",
    foodnumber: "Food Number",
    foodname: "Food Name",
    foodname2: "Food Name 2",
    barcode: "Barcode",
    barcode2: "Barcode 2",
    barcode3: "Barcode 3",
    isbarcodeexist: "Barcode Is Exists",
    unit: "unit",
    salprice1: "Sale Price 1",
    salprice2: "Sale Price 2",
    salprice3: "Sale Price 3",
    salprice4: "Sale Price 4",
    salprice5: "Sale Price 5",
    salprice6: "Sale Price 6",
    cost: "Cost",
    taxtype: "Tax Type",
    integralget: "Integral Get",
    integralfree: "Integral Free",
    memo: "Memo",
    memo2: "Memo 2",
    memo3: "Memo 3",
    isscale: "Is Scale",
    nodiscount: "No Discount",
    isstopsale: "Is Stop Sale",
    noservice: "No Service",
    iscustomerstock: "Is Customer Stock",
    nogiveintegral: "No Give Integral",
    color: "Color",
    foodsetting: "Food",
    othersetting: "Others",
    erpsetting: "ERP",
    membersetting: "Member",
    fontsize: "Font Size",
    forecolor: "Fore Color",
    backcolor: "Background Color",
    ingredient: "Ingredient",
    storage: "Storage",
    origin: "Origin",
    supplierid: "Supplier",
    purchaseqty: "Purchase Qty",
    purchasefoodid: "Purchase Food",
    foodstocktype: "Food Stock Type",
    isstock: "Is Stock",
    issaleautocombination: "Is Sale Auto Combination",
    store: "Store",
    birth: "Birth",
    cellphone: "Cellphone",
    phone1: "Phone 1",
    phone2: "Phone2",
    email: "Email",
    identifycard: "Identify Card",
    gender: "Gender",
    address: "Address",
    uniformnumber: "Uniform Number",
    vehiclenumber: "Vehicle Number",
    customerdegree: "Default Customer Degree",
    contactperson: "Contact Person",
    salesperson: "Salesperson",
    noocard: "No Ocard",
    pickupaddress: "Pickup Address",
    faxnumber: "Fax Number",
    hasbatchnumber: "Batch Number",
    employeetype: "Position",
    isMultiSpecification: "Is Multiple Specification",
    countrycode: "Country Code",
    multispecification: "Multi Specification",
    isspecificationsamepromotion: "Is Specification Same Promotion",
    isspecificationsametaste: "Is Specification Same Taste",
    foodkindfontsize: "Food Kind Font Size",
    foodkindforecolor: "Food Kind Fore Color",
    foodkindbackcolor: "Food Kind Back Color",
    foodfontsize: "Food Font Size",
    foodforecolor: "Food Fore Color",
    foodbackcolor: "Food Back Color",
    language: "Language",
    chinese: "Chinese (ZH)",
    english: "English (EN)",
    japanese: "Japanese (JP)",
    korean: "Korean (KR)",
    french: "French (FR)",
    broadcastid: "Broadcast Id",
  },
  //dataModalPart

  //searchAndTablePart
  searchAndTablePart: {
    number: "Number",
    name: "Name",
    foodkindnumber: "Food Kind Number",
    foodkindname: "Food Kind Name",
    foodnumber: "Food Number",
    foodname: "Food Name",
    barcode: "Barcode",
    taxtype: "Tax Type",
    customernumber: "Customer Number",
    customername: "Customer Name",
    customerdegree: "Customer Degree",
    cellphone: "Cellphone",
    createtime: "Create Time",
    updatetime: "Update Time",
    suppliernumber: "Supplier Number",
    suppliername: "Supplier Name",
    apitype: "API Type",
    thirdpartytype: "Third Party Type",
    notificationtype: "Notification Type",
    webhooktype: "Webhook Type",
  },
  //searchAndTablePart

  //title
  title: {
    combokind: "Combo Kind",
    combo: "Combo",
    directorykind: "Directory Kind",
    directory: "Directory",
    tastekind: "Taste Kind",
    taste: "Taste",
    store: "Store",
    unselectedfood: "Unselected Food",
    selectedfood: "Selected Food",
    unselectedkind: "Unselected Kind",
    selectedkind: "Selected Kind",
    foodimage: "Food Image",
    memberwebimage: "Member Web Image",
    authority: "Authority",
    unselectedterminal: "Unselected Terminal",
    selectedterminal: "Selected Terminal",
    unselectedemployee: "Unselected Employee",
    selectedemployee: "Selected Employee",
    promotion: "Promotion",
    promotiondets: "Promotion Dets",
    unselectedstore: "Unselected Store",
    selectedstore: "Selected Store",
    customerlevels: "Customer Levels",
    invoice: "Invoice",
    sendinvoice: "Send Invoice",
    columnsetting: "Column Setting",
    selectcustomer: "Select Customer",
    selectdirectory: "Select Directory",
    selectmodule: "Select Module",
    selectemployee: "Select Employee",
    selectfood: "Select Food",
    selectfoodkind: "Select Food Kind",
    selectfoodmajorkind: "Select Food Major Kind",
    selecttaste: "Select Taste",
    selecttastekind: "Select Taste Kind",
    selectstore: "Select Store",
    selectsupplier: "Select Supplier",
    selectterminal: "Select Terminal",
    selectwarehouse: "Select Warehouse",
    invoiceimage: "Invoice Image",
    selectinventory: "Select Inventory",
    fullnumber: "Full",
    salpricetype: "Sale Price",
    selectmenu: "Select Menu",
    food: "Food",
    specification: "Specification",
    selectedbrand: "Selected Brand",
    unselectedbrand: "Unselected Brand",
    cangain: "Gained",
    cantgain: "Ungained",
  },
  //title

  //button
  button: {
    food: "Food",
    save: "Save",
    cancel: "Cancel",
    selectfood: "Select Food",
    clear: "Clear",
    food: "Food",
    foodkind: "Food Kind",
    select: "Select",
    setting: "Setting",
    add: "Add",
    edit: "Edit",
    remove: "Remove",
    store: "Store",
    copy: "Copy",
    supplier: "Supplier",
    warehouse: "Warehouse",
    inventory: "Inventory",
    inventorylist: "Inventory",
    topurchase: "To Purchase",
    getoutofsafeqty: "Get Out Of Safe Qty",
    send: "Send",
    print: "Print",
    detail: "Detail",
    sendemail: "Send Email",
    void: "Void",
    start: "Start",
    check: "Check",
    done: "Done",
    ok: "OK",
    backtopreviouspage: "Back To Previous Page",
    import: "Import",
    export: "Export",
    //modules
    frontendfunctions: "Front-End Functions",
    basic: "Basic",
    modules: "Modules",
    reports: "Reports",
    customers: "Customers",
    erp: "ERP",
    accounts: "Accounts",
    download: "Download",
    upload: "Upload",
    //reportTypes
    simple: "Simple",
    detail: "Detail",
    summary: "Summary",
    all: "All",
    sale: "Sale",
    store: "Store",
    statistic: "Statistic",
    daystatistic: "Daystatistic",
    warehouse: "Warehouse",
    food: "Food",
    order: "Order",
    available: "Available",
    standard: "Standard",
    import: "Import",
    cloud: "Cloud",
    auto: "Auto",
  },
  //button

  //option
  option: {
    Default: "Default",
    None: "None",
    G: "Gram",
    TG: "Taiwan Kilogram",
    KG: "Kilogram",
    HG: "100 Grams",
    All: "All",
    YTax: "Including Tax",
    ZTax: "Zero Tax",
    NTax: "No Tax",

    //customerSearchType
    customerSearchType: {
      CustomerNumber: "Customer Number",
      CustomerName: "Customer Name",
      Cellhpone: "Cellhpone",
    },

    //foodSearchType
    foodSearchType: {
      FoodKindName: "Food Kind Name",
      FoodKindNumber: "Food Kind Number",
      FoodName: "Food Name",
      FoodNumber: "Food Number",
      Barcode: "Barcode",
      SupplierNumber: "Supplier Number",
      SupplierName: "Supplier Name",
    },

    //supplierSearchType
    supplierSearchType: {
      SupplierNumber: "Supplier ID",
      SupplierName: "Supplier Name",
      BusinessNumber: "Business ID",
      Telephone: "Telephone",
    },

    //businessClassType
    businessClassType: {
      All: "All",
      Class1: "1",
      Class2: "2",
      Class3: "3",
      Class4: "4",
      Class5: "5",
      Class6: "6",
      Class7: "7",
      Class8: "8",
      Class9: "9",
      Class10: "10",
    },

    //costType
    costType: {
      NormalCost: "Normal Cost",
      AvgCost: "Avg Cost",
      LastCost: "Last Cost",
    },

    employeeType: {
      General: "General",
      Saler: "Saler",
    },

    //customerLevelType
    customerLevelType: {
      DegreeNone: "Degree 0",
      DegreeOne: "Degree 1",
      DegreeSecond: "Degree 2",
      DegreeThird: "Degree 3",
      DegreeFour: "Degree 4",
      DegreeFive: "Degree 5",
      DegreeSix: "Degree 6",
    },

    //customerSaleType
    customerSaleType: {
      None: "None",
      SalPrice: "Sale Price",
      FreePrice: "Free Price",
      Discount: "Discount",
    },

    //degreeType
    degreeType: {
      None: "None",
      Level0: "Level 0",
      Level1: "Level 1",
      Level2: "Level 2",
      Level3: "Level 3",
      Level4: "Level 4",
      Level5: "Level 5",
      Level6: "Level 6",
    },

    //directoryType
    directoryType: {
      None: "None",
      Collection: "Collection",
      Payment: "Payment",
      Sale: "Sale",
      Food: "Food",
      DeleteFood: "Delete Food",
      VoidSale: "Void Sale",
      VoidDeposit: "Void Deposit",
      RejectOnlineOrder: "Reject Online Order",
      StockAdjust: "Stock Adjust",
    },

    //eatSearchType
    eatSearchType: {
      All: "All",
      In: "In",
      Out: "Out",
      Delivery: "Delivery",
      InAndOut: "In And Out",
      InAndDelivery: "In And Delivery",
      OutAndDelivery: "Out And Delivery",
      PickSelf: "PickSelf",
      InAndPickSelf: "In And Pick Self",
      OutAndPickSelf: "Out And Pick Sekf",
      DeliveryAndPickSelf: "Delivery And Pick Self",
      InAndOutAndDelivery: "In And Out And Delivery",
      InAndOutAndPickSelf: "In And Out And Pick Self",
      InAndDeliveryAndPickSelf: "In And Delivery And Pick Self",
      OutAndDeliveryAndPickSelf: "Out And Delivery And Pick Self",
    },

    //foodStockType
    foodStockType: {
      Product: "Product",
      Combination: "Combination",
    },

    //invoiceTargetType
    invoiceTargetType: {
      B2C: "B2C",
      B2B: "B2B",
    },

    //vehicleType
    vehicleType: {
      none: "None",
      cellphone: "Cellphone",
      nature: "Nature",
      donate: "Donate",
      member: "Member",
    },

    //moduleType
    moduleType: {
      Authority: "Authority",
      DayTime: "Day Time",
      Invisible: "Invisible",
      Function: "Function",
      FoodSequence: "Food Sequence",
      Promotion: "Promotion",
    },

    //ocardPromotionType
    ocardPromotionType: {
      Percent: "Percent",
      Price: "Price",
      Redeem: "Redeem",
    },

    //waitingType
    waitingType: {
      Minute: "Minute",
      Hour: "Hour",
      Day: "Day",
    },

    //waitingIntervalType
    waitingIntervalType: {
      Waiting5Mins: "5 minutes",
      Waiting10Mins: "10 minutes",
      Waiting15Mins: "15 minutes",
      Waiting20Mins: "20 minutes",
      Waiting30Mins: "30 minutes",
      Waiting60Mins: "60 minutes",
      Customize: "Customize",
    },

    //reserveDaysType
    reserveDaysType: {
      All: "All",
      Today: "Today",
      Customize: "Customize",
    },

    //overPayType
    overPayType: {
      None: "None",
      OverCharge: "OverCharge",
      CashChange: "CashChange",
      Change: "Change",
    },

    //payType
    payType: {
      Cash: "Cash",
      CreditCard: "Credit Card",
      EasyCard: "Easy Card",
      Coupon: "Coupon",
      PayType1: "PayType 1",
      PayType2: "PayType 2",
      Receivable: "Receivable",
      Deposit: "Deposit",
      LinePay: "Line Pay",
      CouponForStock: "Coupon For Stock",
      iPASS: "iPASS",
      PXPay: "PXPay",
      Scan2Pay: "Scan2Pay",
      PGTalk: "PGTalk",
      JKOPay: "JKOPay",
    },

    //salPriceType
    SalPrice1: "Sal Price 1",
    SalPrice2: "Sal Price 2",
    SalPrice3: "Sal Price 3",
    SalPrice4: "Sal Price 4",
    SalPrice5: "Sal Price 5",
    SalPrice6: "Sal Price 6",

    //promotionType
    TotalPriceForFullQty: "Total Price For Full Qty",
    FreeQtyForFullQty: "Free Qty For Full Qty",
    TotalPiceForMatch: "Total Pice For Match",
    DiscountForFullQty: "Discount For Full Qty",
    SalPriceForFullQty: "Sale Price For Full Qty",
    DiscountSalPrice: "Discount Sale Price",
    FreePriceForFullPrice: "Free Price For Full Price",
    DiscountForFullPrice: "Discount For Full Price",
    DiscountForTheQty: "Discount For The Qty",
    FreePriceForFullQty: "Free Price For Full Qty",
    DiscountForGreaterThanQty: "Discount For Greater Than Qty",
    SameFood: "(Same Food)",

    //ocardPromotionType
    Percent: "Percent",
    Price: "Price",
    Redeem: "Redeem",

    //saleType
    SalPrice: "Sale Price",
    FreePrice: "Free Price",
    Discount: "Discount",

    //taxType
    taxType: {
      NoInvoice: "No Invoice",
      YTax: "Including Tax",
      ZTax: "Zero Tax",
      NTax: "No Tax",
    },

    //tradeStatusType
    Sale: "Sale",
    Void: "Void",

    //purchaseOrderStatusType
    NotFinishedYet: "Not Finished Yet",
    Finished: "Finished",

    //timeType
    Hour: "Hour",
    Day: "Day",
    Month: "Month",
    Season: "Season",
    Year: "Year",

    //rankType
    rankType: {
      None: "None",
      Qty: "Qty",
      Total: "Total",
    },

    //yes or no
    yesOrNo: {
      yes: "Yes",
      no: "No",
    },

    //reportSourceType
    reportSourceType: {
      All: "All",
      Local: "POS",
      Web: "Web",
    },

    //lineOrderImageColorType
    lineOrderImageColorType: {
      Green: "Green",
      Blue: "Blue",
      Yellow: "Yellow",
      Orange: "Orange",
    },

    //foodStatusType
    foodStatusType: {
      All: "All",
      Normal: "Normal",
      Discontinued: "Discontinued",
    },

    //visionType
    visionType: {
      Myopia: "Myopia",
      Hyperopia: "Hyperopia",
      Contacts: "Contacts",
      Other: "Other",
    },

    //dateSourceType
    dateSourceType: {
      Delivery: "Delivery Date",
      Account: "Account Date",
      Trade: "Trade Date",
    },

    //changePriceType
    changePriceType: {
      Total: "Total",
      Qty: "Qty",
      Function: "(With Function)",
    },

    //qtyType
    qtyType: {
      All: "All",
      GreaterThanZero: "Greater Than Zero",
      LessThanZero: "Less Than Zero",
      Zero: "Zero",
      GreaterAndEqualZero: "Greater And Equal Zero",
    },

    //elecInvoiceTitleType
    elecInvoiceTitleType: {
      Text: "Text",
      Picture: "Picture",
    },

    //apiType
    apiType: {
      Web: "Web",
      Broadcast: "Broadcast",
      Order: "Order",
      Member: "Member",
    },

    //thirdPartyType
    thirdPartyType: {
      None: "None",
      LinePay: "LinePay",
      PGTalk: "PGTalk",
      MyCard: "MyCard",
      PXPay: "PXPay",
      SurveyCake: "SurveyCake",
      Ocard: "Ocard",
      UberEats: "UberEats",
      Showmore: "Showmore",
      Mitake: "Mitake",
      Digiwin: "Digiwin",
    },

    //systemLogType
    systemLogType: {
      None: "None",
      DeviceChangeCpu: "Device Change Cpu",
      UserChangePassword: "User Change Password",
      SystemChangeUserPassword: "System Change User Password",
      InitailizeSale: "Initailize Sale",
      InitailizeERP: "Initailize ERP",
      DeleteUberEats: "Delete UberEats",
    },

    //notificationType
    notificationType: {
      All: "All",
      None: "None",
      OnlineOrder: "Online Order",
      UberEatsOrder: "UberEats Order",
      UberEatsOrderCancel: "UberEats Order Cancel",
      TableOrder: "Table Order",
    },

    //webhookType
    webhookType: {
      None: "None",
      Order: "Order",
      UbereatsOrder: "Ubereats Order",
      UbereatsOrderCancel: "Ubereats Order Cancel",
      UbereatsStoreProvision: "Ubereats Store Provision",
      UbereatsStoreDeProvision: "Ubereats Store De Provision",
      SendGrid: "Send Grid",
      Showmore: "Showmore",
    },

    //customerIdFillType
    customerIdFillType: {
      manually: "Manually",
      autofordate: "Auto For Date",
    },
  },
  //option

  //itemModal modalPart form
  modal: {
    all: "All",
    default: "Default",
    none: "None",
    name: "Name",
    number: "Number",
    number2: "Number",
    memo: "Memo",
    kind: "Kind",
    kind2: "Kind",
    food: "Food",
    foodkind: "Food Kind",
    foodmajorkind: "Food Major Kind",
    unitprice: "Unit Price",
    store: "Store",
    modulestore: "Module Store",
    password: "Password",
    birth: "Birthday",
    phone: "Phone",
    cellphone: "Cellphone",
    telephone: "Telephone",
    email: "Email",
    identifycard: "Identify Card",
    gender: "Gender",
    departmentid: "Department ID",
    defaultwarehouse: "Default Warehouse",
    warehouse: "Warehouse",
    address: "Address",
    cloudterminalid: "Cloud Terminal ID",
    isnotsalesperson: "Not Salesperson",
    foodnumber: "Food Number",
    foodname: "Food Name",
    foodmajorkindid: "Food Major Kind",
    foodkindnumber: "Food Kind Number",
    foodkindname: "Food Kind Name",
    fontsize: "Font Size",
    forecolor: "Fore Color",
    backcolor: "Background Color",
    samecolor: "Food Button Same Kind",
    nosamecolor: "Enpty Button Same Kind",
    isfoodsamefontsize: "Food Font Same Kind",
    value: "Value",
    unit: "Unit",
    functiontype: "Function Type",
    paytype: "Pay Type",
    overpaytype: "Over Pay Type",
    price: "Price",
    pricemarkup: "Price Markup",
    account: "Account",
    identifier: "Identifier",
    totallength: "Total Length",
    pricelabeltype: "Price Label Type",
    foodposition: "Food Position",
    foodlength: "Food Length",
    priceposition: "Price Position",
    pricelength: "Price Length",
    pricedecimalnumber: "Price Decimal Number",
    qty: "Qty",
    qtyposition: "Qty Position",
    qtylength: "Qty Length",
    qtydecimalnumber: "Qty Decimal Number",
    singlediscount: "Single Addition",
    totaldiscount: "Total Discount",
    singleaddition: "Single Addition",
    totaladdition: "Total Addition",
    singleallowance: "Single Allowance",
    totalallowance: "Total Allowance",
    parttotaldiscount: "Part Total Discount",
    principal: "Principal",
    uniformnumber: "Uniform Number",
    isselect: "Is Select",
    elecinvoicetitle: "Electronic Invoice Title",
    companyname: "Company Name",
    companyaddress: "Company Address",
    lycustomernumber: "LY Customer Number",
    lywarehousenumber: "LY Warehouse Number",
    lyuniformnumber: "LY Uniform Number",
    discountprice: "Discount Price",
    ntd: "NTD",
    min: "Min",
    max: "Max",
    displayname: "Display Name",
    promotionname: "Promotion Name",
    promotiontype: "Promotion Type",
    copyto: "Copy To",
    daymodule: "Day Module",
    customizefood: "Customize Food",
    isrepeat: "Is Repeat",
    total: "Total",
    total2: "Total",
    satisfy: "Satisfy",
    amount: "Amount",
    amount2: "Amount",
    buy: "Buy",
    present: "Present",
    freeprice: "Free Price",
    discount: "Discount",
    sale: "Sale",
    from: "From",
    greaterthan: "Greater than",
    customerdegree: "Customer Level",
    customergrading: "Customer Grading",
    point: "Point",
    p: "Point",
    exchange: "Every",
    candiscount: "point can discount",
    ntdtoget: "NTD to get",
    nopromotioncombo: "No Promotion Combo",
    discountkind: "Discount Kind",
    memberspecificstore: "Member Specific Store",
    degree: "Degree",
    validmonths: "Valid Months",
    accumulatedamounts: "Accumulated Amounts",
    accumulatedmonths: "Accumulated Months",
    keepamounts: "Keep Amounts",
    keepdays: "Keep Months",
    table: "Table",
    inventorydate: "Inventory Date",
    inventorynumber: "Inventory Number",
    writername: "Employee Name",
    warehouse: "Warehouse",
    noinventory: "No Inventory",
    import: "Import",
    export: "Export",
    date: "Date",
    purchasedate: "Date",
    purchasenumber: "Purchase Number",
    purchasereturnnumber: "Purchase Return Number",
    accountdate: "Account Date",
    supplier: "Supplier",
    buyer: "Buyer",
    contactperson: "Contact Person",
    invoicenumber: "Invoice Number",
    invoicedate: "Invoice Date",
    taxtype: "Tax Type",
    istaxaddition: "Is Tax Addition",
    importsafeqty: "Import Safe Qty",
    customer: "Customer",
    invoicetargettype: "Invoice Target Type",
    vehicletype: "Vehicle Type",
    vehiclenumber: "Vehicle Number",
    vehicletypenumber: "Vehicle Type Number",
    format1: "Format 1",
    format2: "Format 2 (A4)",
    format3: "Format 3 (中一刀)",
    format4: "Format 1 (A4)",
    stockadjustnumber: "Stock Adjust Number",
    stockadjusttype: "Stock Adjust Type",
    directory: "Directory",
    stocktransferdate: "Stock Transfer Date",
    stocktransfernumber: "Stock Transfer Number",
    fromwarehouse: "From Warehouse",
    towarehouse: "To Warehouse",
    checkername: "Employee Name",
    salesperson: "Salesperson",
    timetype: "Time Type",
    ranktype: "Rank Type",
    eattype: "Eat Type",
    salepriceseperate: "Sale Price Seperate",
    combine: "Combine",
    seperate: "Seperate",
    businessclass: "Business Class",
    hide: "Hide",
    show: "Show",
    today: "Today",
    foodsupplier: "Food Supplier",
    status: "Status",
    employee: "Employee",
    terminal: "Terminal",
    basic: "Basic",
    downloaditem: "Download Item",
    oopsmessage: "Sorry, this page doesn't seem to exist!",
    foodrank: "Food Rank",
    foodkindrank: "Food Kind Rank",
    storedata: "Store Data",
    timedata: "Time Data",
    people: "People",
    salecounts: "Sale Counts",
    addcategory: "Add",
    categoryname: "Category Name",
    additem: "Add",
    corresponditem: "Correspond Item",
    daymodule: "Day Module",
    waitingtype: "Fastest Waiting Unit",
    waitinginterval: "Waiting Interval",
    waitingintervalcustomize: "Customize Waiting Interval (Minute)",
    waitingnum: "Fastest Waiting Time",
    reservedays: "Reserve Days",
    reservedayscustomize: "Customize Reserve Days (Day)",
    lastorderminutes: "Last Order Minutes",
    specialist: "Employee Name",
    combination: "Combination",
    paymentdate: "Payment Date",
    paymentnumber: "Payment Number",
    costtype: "Cost Type",
    liffid: "LIFF ID",
    messagetoken: "Message Token",
    qrcodeurl: "QR Code Url",
    channelid: "Channel ID",
    channelsecret: "Channel Secret",
    isshowall: "Show All",
    memberupgrade: "Member Upgrade",
    memberupgradesetting: "Member Upgrade Setting",
    reportsourcetype: "Source",
    surveycakeurl: "SurveyCake Url",
    foodstatus: "Food Status",
    visiontype: "Vision Type",
    builddate: "Build Date",
    builder: "Builder",
    prescription: "Prescription",
    batchnumber: "Batch Number",
    accountdate: "Account Date",
    faxnumber: "Fax Number",
    batchnumber: "Batch Number",
    search: "Search",
    keyword: "Keyword",
    invoicetitle: "Invoice Title",
    integrationenabled: "Integration Enabled",
    ubereatspromotionfood: "UberEats Promotion Food",
    nogiveintegral: "No Give Integral",
    elecinvoicetitletype: "Invoice Title Type",
    nogiveintegral: "No Give Integral",
    nogiveintegralforcash: "Cash",
    nogiveintegralforcreditcard: "Credit Card",
    nogiveintegralforpaytype1: "Pay Type 1",
    nogiveintegralforpaytype2: "Pay Type 2 ",
    nogiveintegralforreceivable: "Receivable",
    nogiveintegralfordiscount: "Discount",
    specificationtypename: "Specification Type",
    specificationname: "Specificationtype Name",
    onlineorderinsalpricetype: "Online Order In Sale Price Type",
    onlineorderpickselfsalpricetype: "Online Order Pick Self Sale Price Type",
    period: "Period",
    indonetitle: "In Done Title",
    outdonetitle: "Out Done Title",
    qrcode: "QRcode",
    importpromotion: "Import Promotion",
    warehousetype: "Warehouse Type",
    isfulldownload: "Full Download",
    customeridfilltype: "Customer ID Rule",
    client: "Client",
    importbrand: "Import Brand",
    maxdiscount: "Max Discount",
    maxfree: "Max Free",
    english: "English",
    japanese: "Japanese",
    korean: "Korean",
    french: "French",

    //modalTitle
    addaccount: "New Account",
    editaccount: "Edit Account",
    adddirectorykind: "New Directory Kind",
    editdirectorykind: "Edit Directory Kind",
    adddirectory: "New Directory",
    editdirectory: "Edit Directory",
    addemployee: "New Employee",
    editemployee: "Edit Employee",
    addcombokind: "New Combo Kind",
    editcombokind: "Edit Combo Kind",
    adddepartment: "New Department",
    editdepartment: "Edit Department",
    addfoodkind: "New Food Kind",
    editfoodkind: "Edit Food Kind",
    addfoodmajorkind: "New Food Major Kind",
    editfoodmajorkind: "Edit Food Major Kind",
    addfood: "New Food",
    editfood: "Edit Food",
    addfunction: "New Function",
    editfunctionbtn: "Edit Function Button",
    addpay: "New Pay",
    editpay: "Edit Pay",
    addpricelabel: "New Price Label",
    editpricelabel: "Edit Price Label",
    addstore: "New Store",
    editstore: "Edit Store",
    addsupplier: "New Supplier",
    editsupplier: "Edit Supplier",
    addtastekind: "New Taste Kind",
    edittastekind: "Edit Taste Kind",
    addtaste: "New Taste",
    edittaste: "Edit Taste",
    elecinvoice: "Electronic Invoice",
    editterminal: "Edit Terminal",
    batchprice: "Batch Price",
    addwarehouse: "New Warehouse",
    editwarehouse: "Edit Warehouse",
    addmodule: "New Module",
    editmodule: "Edit Module",
    addpromotion: "New Promotion",
    editpromotion: "Edit Promotion",
    addpromotiondets: "New Promotion Dets",
    editpromotiondets: "Edit Promotion Dets",
    addcustomer: "New Customer",
    editcustomer: "Edit Customer",
    addintegralrule: "New Rule",
    editintegralrule: "Edit Rule",
    addmemberupgraderule: "New Rule",
    editmemberupgraderule: "Edit Rule",
    addocardrule: "New Rule",
    editocardrule: "Edit Rule",
    addtable: "New Table",
    edittable: "Edit Table",
    importinventory: "Import Inventory",
    exportinventory: "Export Inventory",
    importsale: "Import Sale",
    importfood: "Import Food",
    importfoodkind: "Import Food Kind",
    importfoodmajorkind: "Import Food Major Kind",
    importsupplier: "Import Supplier",
    importcustomer: "Import Customer",
    adddownload: "New Download",
    addcategory: "New Category",
    editcategory: "Edit Category",
    additem: "New Item",
    edititem: "Edit Item",
    addmenu: "New Menu",
    editmenu: "Edit Menu",
    select: "Select",
    addchangepriceforprice: "Add Change Price For Price",
    editchangepriceforprice: "Edit Change Price For Price",
    addchangepriceforqty: "Add Change Price For Qty",
    editchangepriceforqty: "Edit Change Price For Qty",
    errormessage: "Error Message",
    thirdparty: "Third Party",
    system: "System",
    notification: "Notification",
    webhook: "Webhook",
    eatsearchtype: "Eat Type",
    addbrand: "Add Brand",
    editbrand: "Edit Brand",
  },
  //itemModal modalPart

  //placeholder
  placeholder: {
    dbselect: "Double Click To Select",
    dbselectsupplier: "Double Click To Select Supplier",
    dbselectcustomer: "Double Click To Select Customer",
    dbselectfood: "Double Click To Select Food",
    dbselectwarehouse: "Double Click To Select Warehouse",
    dbselectbuyer: "Double Click To Select Buyer",
    dbselectsalesperson: "Double Click To Select Salesperson",
    dbselectdirectory: "Double Click To Select Directory",
    dbselectcombination: "Double Click To Select Combination",
    inputfoodnumberorbarcode: "Input Food Number Or Barcode",
    inputfoodnumberordb: "Input Food Number Or Double Click To Select",
    inputfoodnumber: "Input Food Number",
    inputcustomernumberordb: "Input Customer Number Or Double Click To Select",
    inputcustomernumber: "Input Customer Number",
    inputsalernumberordb: "Input Saler Number Or Double Click To Select",
    inputwarehousenumberordb:
      "Input Warehouse Number Or Double Click To Select",
    inputsuppliernumberordb: "Input Supplier Number Or Double Click To Select",
    inputbuyernumberordb: "Input Buyer Number Or Double Click To Select",
    chooseanimage: "Choose An Image",
  },
  //placeholder

  //func.message
  funcMsg: {
    importsuccess: "Import Success",
    plsselectterminal: "Please Select A Terminal",
    plsselectitem: "Please Select An Item",
    plsselectdata: "Please Select A Data",
    plsselectsupplier: "Please Select A Supplier",
    plsselectwarehouse: "Please Select A Warehouse",
    plsselectcustomer: "Please Select A Customer",
    plsselectdiscountkind: "Please Select A Discount Kind",
    plsselectcombokind: "Please Select A Combo Kind",
    thispaymentdidnthavedet: "This Payment Didn't Have Detail",
    cantfindthisdata: "Can't Find This Data",
    atleastsetoneprice: "At Least Set One Price",
    thisreceiptdidnthavedet: "This Receipt Didn't Have Detail",
    thisrulecantsetfood: "This Rule Can't Set Food",
    areyousuretodeletethis: "Are You Sure To Delete This",
    success: "Success",
    updatesuccess: "Update Success",
    removesuccess: "Remove Success",
    imageupdatesuccess: "Image Update Success",
    imageremovesuccess: "Image Remove Success",
    imagesizecantover150kb: "Image Size Can't Over 150KB",
  },
  //func.message
};

export default en;
